import Api from 'cox_city_services_platform_api';

import { setErrorEventData } from '@cox2m/city-services-ui-components/src/funcs';

const {
  ApiClient,
  AuthApi,
  AppsApi
} = Api;

/**
 * Initialize the API client
 */
const initApiClient = () => {
  const apiClient = new ApiClient();
  apiClient.basePath = 'SMART_CITIES_API_HOST';
  apiClient.defaultHeaders = {
    'Access-Control-Allow-Origin': '*'
  };

  return apiClient;
};
const apiClient = initApiClient();

const authApi = new AuthApi(apiClient);
const appsApi = new AppsApi(apiClient);


/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = (token) => {
  apiClient.authentications["API_TOKEN"].apiKey = token;
};

/**
 * Handles a logout request.
 * @param {string} token
 */
export const logout = async (token) => {
  try {
    setToken(token);
    const response = await authApi.logout();
    return { fulfilled: true, ...response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'logout');
    return { fulfilled: false, ...error.body };
  }
};

/**
 * Retrieve all app store items
 * @param {string} token
 * @param {number} limit
 * @param {number} offset
 */
export const getAppStoreListing = async (token, limit = 100, offset = 0) => {
  try {
    setToken(token);
    const response = await appsApi.getAppsStore({ limit, offset });
    return { fulfilled: true, collection: response.categories };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-apps-store');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

/**
 * Retrieve an app vendor by its id
 * @param {string} token
 * @param {id} string
 */
export const getAppsVendorsById = async (token, id) => {
  try {
    setToken(token);
    const response = await appsApi.getAppsVendorsById(id);
    return { fulfilled: true, vendor: response };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-apps-vendors-by-id');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};


/**
 * Retrieve an app by its id
 * @param {string} token
 * @param {id} string
 */
export const getAppById = async (token, id) => {
  try {
    setToken(token);
    const response = await appsApi.getAppStoreById(id);
    return { fulfilled: true, app: response[0] };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-app-store-by-id');
    return { fulfilled: false, error: error.body, status: error.status }
  }
};

 /**
   * Schedule a demo to know about an application
   * @param {String} appId
   */
export const scheduleDemo = async (appId) => {
  try {
    appsApi.appScheduleDemo(appId);
    return { fulfilled: true};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'app-schedule-demo');
    return { fulfilled: false, error: error.body, status: error.status }
  }
}


 /**
   * Get the response of the user to know if the applications comparison screen is useful for that given user
   */
export const setComparisonHelpfulness = async () => {
  return { fulfilled: true};
}
