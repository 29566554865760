<script>
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import Router from 'svelte-spa-router';
  import routes from './routes';

  import {staticMenuOptions} from './menuOptions/populateMenu';
  import {user, menuOpts} from './stores';
  import {onMount} from "svelte";
  import {getCookieByName, getUser, initializePendo} from "@cox2m/city-services-ui-components/src/funcs";

  const TOKEN = getCookieByName(`${'ENV'}_token`);

  const createLatestUrlCookie = () => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 600000);
    document.cookie = `${'ENV'}_cox2mlatesturl=${window.location.href};expires=${futureDate.toUTCString()};domain=cox2m.com;`;
  }

  const routeLoaded = event => {
    window.gtag("config", "GA_TRACKING_ID", {
      page_title: event.detail.name,
      page_path: event.detail.location,
    });

    createLatestUrlCookie();
  };

  onMount(async () => {
    const USER_DATA = await getUser(TOKEN) || '{}';
    user.logout();
    user.saveUser({
      ...USER_DATA,
      isCoxUser: USER_DATA.organizationId === 'O_000000'
    });

    const account = {
      id: $user.personId,
      givenName: $user.givenName,
      familyName: $user.familyName,
      email: $user.email
    };
    initializePendo(account, account);
  })

  menuOpts.updateMenuValues(staticMenuOptions());
</script>

<style>
  :global(body) {
    display: flex;
    background-color: var(--cox2m-clr-brand-700);
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    font-family: 'montserrat', 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
      'Helvetica Neue', sans-serif;
  }

   :global(::-webkit-scrollbar) {
    width: 10px;
  }

  /* Track */
  :global(::-webkit-scrollbar-track) {
    background: var(--cox2m-clr-neutral-400);
    border-radius: 10px;
  }

  /* Handle */
  :global(::-webkit-scrollbar-thumb) {
    background: var(--cox2m-clr-neutral-600);
    border-radius: 10px;
  }

  /* Handle on hover */
  :global(::-webkit-scrollbar-thumb:hover) {
    background: var(--cox2m-clr-neutral-700);
  }
</style>

<svelte:head>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'GA_TRACKING_ID');
    window.gtag = gtag;
  </script>
  <script>
    const pendoApiKey = `${'PENDO_API_KEY'}`;
    (function(apiKey) {
      (function(p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src =
          'https://cdn.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(pendoApiKey);
  </script>
</svelte:head>
<Modal>
  <Router {routes} on:routeLoaded={routeLoaded} />
</Modal>
