<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import ConfirmationModal from '../shared/ConfirmationModal.svelte';
  import {scheduleDemo} from '../../actions';

  export let appInfo = {};
  let isDemoScheduled = false;

  const sendEmailToScheduleDemo = () => {
    scheduleDemo(appInfo.id)
  }
</script>

<style>
  .btn-demo {
    background-color: var(--cox2m-clr-brand-400);
    font-size: var(--cox2m-fs-1000);
  }
  .back-button {
    min-width: var(--cox2m-spacing-25-units);
  }
  @media only screen and (max-width: 600px) {
    .btn-demo {
      width: 100%;
    }
  }
</style>

<div class="w-100 row justify-content-between align-items-center">
  <div class="col mr-5">
    <button
      type="button"
      class="btn btn-light bg-white border shadow-sm"
      on:click={() => {
        window.history.back();
      }}>
      <div class="col-sm-auto align-items-center back-button">

        <Icon
          icon="chevron-left"
          size="12px"
          color="var(--cox2m-clr-neutral-black)"
          className="mb-1 btn p-1 active-app-check-icon rounded-circle" />
        Back
      </div>
    </button>
  </div>
  <div class="ml-3">
    <em class="mr-3 text-muted " id="application-status">{appInfo.status}</em>
    <button
      data-toggle="modal"
      data-target="#schedule-demo-modal"
      id="schedule-demo-button"
      type="button"
      class="btn btn-demo text-white shadow-sm px-5 py-2">
      {#if !isDemoScheduled}
        <b>Schedule a demo</b>
      {:else}
        <b>Confirmation Sent!</b>
      {/if}
    </button>
    <div />
  </div>
</div>

<ConfirmationModal
  on:modalConfirmationSucces={() => isDemoScheduled = true}
  id="schedule-demo-modal"
  defaultTitle='Schedule a Demo?'
  defaultBody={'Click the confim button to schedule a demo of the ' + appInfo.name + ' application'}
  confirmationFunction={sendEmailToScheduleDemo}
  successTitle='Confirmation Sent!'
  successBody='The Cox Smart Communities Team has recieved your request and will contact you within one hour'
  failureTitle='We are Sorry!'
  failureBody='We have some troubles sending your request, please try again later'
  confirmationButtonText='Yes, Schedule Demo'
  />
