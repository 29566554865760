<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import {createEventDispatcher} from 'svelte';

  export let defaultTitle;
  export let defaultBody;
  export let confirmationFunction;
  export let successTitle;
  export let successBody;
  export let failureTitle;
  export let failureBody;
  export let confirmationButtonText = 'Confirm';
  export let id = 'confirmationModal';

  let displaySuccessContent = false;
  let displayFailureContent = false;
  let loading = false;

  const  WAITING_TIME_TO_RESET_MODAL_MESSAGE = 750; //ms

  const dispatch = createEventDispatcher();
  const successEventDispatcher = () => {
    dispatch('modalConfirmationSucces');
  };
  const failureEventDispatcher = () => {
    dispatch('modalConfirmationFailure');
  };

  const functionCallHandler = async fn => {
    loading = true;
    try {
      await fn();
      displayFailureContent = false;
      displaySuccessContent = true;
      successEventDispatcher();
    } catch (error) {
      displaySuccessContent = false;
      displayFailureContent = true;
      failureEventDispatcher();
    }
    loading = false;
  };
</script>

<style>
  .btn-demo {
    background-color: var(--cox2m-clr-brand-400);
  }
  .loading-container {
    min-height: 150px;
  }
</style>

<div
  class="modal fade"
  {id}
  tabindex="-1"
  role="dialog"
  aria-labelledby={id + 'Label'}
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content container">
      {#if !loading}
        <div class="modal-body modal-body text-center pb-0">

          {#if !displaySuccessContent && !displayFailureContent}
            <h4 id={id+"-default-title"}>{defaultTitle}</h4>
            <p id={id+"-default-body"} class="m-0">{defaultBody}</p>
          {:else if displaySuccessContent}
            <h4 id={id+"-success-title"}>{successTitle}</h4>
            <p id={id+"-success-body"}>{successBody}</p>
          {:else if displayFailureContent}
            <h4 id={id+"-failure-title"}>{failureTitle}</h4>
            <p id={id+"-failure-body"}>{failureBody}</p>
          {/if}

        </div>
        <div id="modal-buttons-container" class="container-fluid row m-auto">
          {#if !displaySuccessContent && !displayFailureContent}
            {#if confirmationFunction}
              <div class="col-12">
                <button
                  type="button"
                  id={id+"-confirmation-button"}
                  class="btn btn-demo text-white mt-3 mb-1 w-100"
                  on:click={() => functionCallHandler(confirmationFunction)}>
                  {confirmationButtonText}
                </button>
              </div>
            {/if}
          {/if}
          <div class="col-12 mb-3">
            <button
              type="button"
              class="btn btn-light my-1 w-100"
              id={id+"-cancel-button"}
              data-dismiss="modal"
              on:click={() => {
                setTimeout(() => {
                  displaySuccessContent = false;
                  displayFailureContent = false;
                }, WAITING_TIME_TO_RESET_MODAL_MESSAGE);
              }}>
              Close
            </button>
          </div>
        </div>
      {:else}
        <div
          class="row align-items-center justify-content-center loading-container">
          <LoadingSpinner />
        </div>
      {/if}

    </div>
  </div>
</div>
