<script>
  import ComparisonScreenSubHeader from '../../components/comparison-screen/ComparisonScreenSubheader.svelte';
  import AppComparisonTable from '../../components/comparison-screen/AppComparisonTable.svelte';
  import VendorComparisonTable from '../../components/comparison-screen/VendorComparisonTable.svelte';
  import {setComparisonHelpfulness} from '../../actions.js';

  export let appsToCompare = [];
  export let onPageChange = () => {};
  export let token = '';

  let allApplicationFeatures = [];

  const onHelpfulPageSelection = helpfulValue => {
    setComparisonHelpfulness(helpfulValue);
  };

  const applicationFeaturesHandling = () => {
    appsToCompare.forEach(app => {
      app.features.forEach(feature => allApplicationFeatures.push(feature.key));
    });
    allApplicationFeatures = [...new Set(allApplicationFeatures)];
  };

  applicationFeaturesHandling(appsToCompare);
</script>

<div
  class="row d-flex align-items-center justify-content-between px-5 py-3
  adjust-top">
  <div class="mb-2 row w-100">
    <ComparisonScreenSubHeader {onPageChange} />
  </div>
  <div class="container-fluid p-0">
    <h1 clas="my-4">App Comparison</h1>
    <h4 class="mt-5 mb-2">App Features</h4>
    <AppComparisonTable {appsToCompare} {allApplicationFeatures} />
  </div>

  <div class="container-fluid p-0">
    <h4 class="mt-4 mb-2">Vendor Information</h4>
    <VendorComparisonTable {appsToCompare} {token} />
  </div>
  <div class="row m-0 mt-5 w-100 justify-content-end">
    <div class="d-flex">
      <span class="mr-2">
        <b>Was this page helpful?</b>
      </span>
      <label class="mr-1">
        <input
          type="radio"
          id="page-was-helpful-radio"
          on:click={onHelpfulPageSelection(true)}
          name="helpfulPage" />
        <span class="lbl">Yes</span>
      </label>
      <label class="mr-1">
        <input
          type="radio"
          id="page-was-not-helpful-radio"
          on:click={onHelpfulPageSelection(false)}
          name="helpfulPage" />
        <span class="lbl">No</span>
      </label>
    </div>
  </div>
</div>
