<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import AppFetchingError from '../../components/app-details/AppFetchingErrorMessage.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import ScreenShotGallery from '../../components/app-details/ScreenShotGallery.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import SubHeader from '../../components/app-details/SubHeader.svelte';
  import FeatureList from '../../components/app-details/FeatureList.svelte';
  import Unauthorized from '../../modals/Unauthorized.svelte';

  import {
    shouldDisplayUnauthorizedModal,
    getCookieByName
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {getAppById} from '../../actions';
  import {push} from 'svelte-spa-router';
  import {menuOpts, user} from '../../stores';
  import {onMount} from 'svelte';

  export let params;

  const token = getCookieByName(`${"ENV"}_token`);

  let modalContext;
  let appInfo;
  let pageLoading = true;
  let appFetchingError = false;

  const BASE_VENDOR_ROUTE = '/app-store/vendor/';

  onMount(async () => {
    const response = await getAppById(token, params.id);
    if (response.fulfilled) {
      appInfo = response.app;
    } else {
      if (response.status === 401) {
        shouldDisplayUnauthorizedModal(modalContext, Unauthorized);
      } else {
        appFetchingError = true;
      }
    }
    pageLoading = false;
  });

  const goToVendorPage = () => {
    push(
      `${BASE_VENDOR_ROUTE}${appInfo.categoryName}/${appInfo.vendor.vendorId}`
    );
  };
</script>

<style>
  .adjust-top {
    position: relative;
    top: -14.5px;
  }
  .app-vendor-name {
    color: var(--cox2m-primary-color-4);
  }
</style>

<Modal bind:this={modalContext}>
  <MainScreen title="App Detail" menuOpt={$menuOpts} user={$user} appSlug="extra-smart" showAlertsBanner={false}>
    <div slot="main-dashboard">
      {#if pageLoading}
        <div
          class="row col w-100 h-100 justify-content-center align-content-center">
          <LoadingSpinner />
        </div>
      {:else if !appFetchingError}
        <div
          class="row d-flex align-items-center justify-content-between px-5 py-3
          adjust-top"
          id="app-details-container">
          <SubHeader {appInfo} />
          <div class="container-fluid mt-5">
            <div class="vendor-info">
              <h3 class="mb-3">
                <b id="application-name">{appInfo.name}</b>
              </h3>
              <h5 class="mb-3 text-truncate">
                <b id="application-description">{appInfo.description}</b>
              </h5>
              <h5 class="app-vendor-name">
                <b>
                  <a
                    class="text-dark"
                    id="application-vendor"
                    on:click={goToVendorPage}
                    href="javascript:void(0);">
                    {appInfo.vendor.name}
                  </a>
                </b>
              </h5>
            </div>
            <div class="general-info">
              <div class="mt-5" id="feature-list-container">
                {#if appInfo.features.length > 0}
                  <h5>
                    <b>List of Features</b>
                  </h5>
                  <FeatureList features={appInfo.features} />
                {:else}
                  <em class="my-1">
                    There are no application features to display right now
                  </em>
                {/if}
              </div>
              {#if appInfo.screenShots}
                <ScreenShotGallery screenshots={appInfo.screenShots} />
              {/if}
            </div>
          </div>
        </div>
      {:else}
        <AppFetchingError />
      {/if}

    </div>
  </MainScreen>
</Modal>
