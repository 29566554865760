<script>
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import ConfirmationModal from '../shared/ConfirmationModal.svelte';
  import CopyToClipboard from '../shared/CopyToClipboard.svelte';
  import FeatureColumn from '../FeatureColumn.svelte';

  import {MOBILE_REGEX_PART_1, MOBILE_REGEX_PART_2} from '../../constants';
  import {onMount} from 'svelte';
  import {css} from 'emotion';

  export let allApplicationFeatures = [];
  export let appsToCompare = [];

  let appComparisonTable;
  let contactVendorsTableRow;

  const columns = [
    {label: 'Feature', key: 'feature', Component: FeatureColumn}
  ];
  const rowsObj = {};
  const rows = [];

  appsToCompare.forEach(app => {
    columns.push({label: app.name, key: app.id});
    if (app.features) {
      app.features.forEach(feature => {
        if (rowsObj[feature.key]) {
          rowsObj[feature.key][app.id] = feature.value;
        } else {
          rowsObj[feature.key] = {[app.id]: feature.value};
        }
      });
    }
  });

  for (const key in rowsObj) {
    if (Object.hasOwnProperty.call(rowsObj, key)) {
      rows.push({feature: key, ...rowsObj[key]});
    }
  }

  let selectedVendorEmail;
  let selectedVendorPhone;
  let textToCopy = '';

  window.mobileCheck = () => {
    let check = false;
    (function(a) {
      if (
        MOBILE_REGEX_PART_1.test(a) ||
        MOBILE_REGEX_PART_2.test(a.substr(0, 4))
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const copyToClipboard = str => {
    textToCopy = '.';
    textToCopy = str;
  };

  const callVendorConfirmationFunction = () => {
    if (window.mobileCheck()) {
      window.open('tel:' + selectedVendorPhone);
    } else {
      copyToClipboard(selectedVendorPhone);
    }
  };
  const emailVendorConfirmationFunction = () => {
    window.open(`mailto:${selectedVendorEmail}`)
  };

  onMount(() => {
    if(document.getElementById('app-comparison-table')){
      document.getElementById('app-comparison-table').append(contactVendorsTableRow);
    }
  });
</script>

<style>
  .btn-outline-primary {
    color: var(--cox2m-clr-brand-400);
    border-color: var(--cox2m-clr-brand-400);
  }
  .btn-outline-primary:hover {
    color: var(--cox2m-clr-neutral-white);
    background-color: var(--cox2m-clr-brand-400);
  }
</style>

<div class="w-100 mt-5">
  {#if allApplicationFeatures.length > 0}
    <Table
      bind:this={appComparisonTable}
      columnRowClassName="text-white {css`background-color: var(--cox2m-clr-brand-700) !important;`}"
      id='app-comparison-table'
      containerClassName="table-container border rounded bg-white text-dark
      overflow-auto"
      {columns}
      {rows} />

    <thead bind:this={contactVendorsTableRow} class="border-0">
      <tr>
        <th class="border-0" id="contactVendorsTableRow"/>

        {#each appsToCompare as app}
          <th class="border-0" >
            <div class="row px-2 mt-4 mb-2 mx-0 justify-content-center">
              {#if app.vendor.phoneNumber}
                <button
                  class="col mx-1 p-3 btn btn-outline-primary"
                  id={app.id+"-call-vendor-button"}
                  on:click={() => {
                    selectedVendorPhone = app.vendor.phoneNumber;
                  }}
                  data-toggle="modal"
                  data-target="#callVendorModal"
                  type="button">
                  Call Vendor
                </button>
              {/if}
              {#if app.vendor.emailAddress}
                <button
                  class="col mx-1 p-3 btn btn-outline-primary"
                  id={app.id+"-email-vendor-button"}
                  on:click={() => {
                    selectedVendorEmail = app.vendor.emailAddress;
                  }}
                  data-toggle="modal"
                  data-target="#emailVendorModal"
                  type="button">
                  Email Vendor
                </button>
              {/if}
            </div>
          </th>
        {/each}
      </tr>
    </thead>
  {:else}
    <p>There are no features to display right now, please come back soon!</p>
  {/if}
</div>

<div class="d-none">
  <textarea id="vendor-phone">{selectedVendorPhone}</textarea>
</div>

<ConfirmationModal
  id="call-vendor-modal"
  defaultTitle='Contact Vendor?'
  defaultBody='Click confirm to start a call with you and the vendor'
  confirmationButtonText='Yes, Call Vendor'
  confirmationFunction={callVendorConfirmationFunction}
  successTitle='Hang on there!'
  successBody='we are about to make your call'
  failureTitle='We are sorry!'
  failureBody="We couldn't place your call, please try again later" />
<ConfirmationModal
  id="email-vendor-modal"
  defaultTitle='Contact Vendor?'
  defaultBody='Click confirm to start an email with you and the vendor'
  confirmationButtonText='Yes, Email Vendor'
  confirmationFunction={emailVendorConfirmationFunction}
  successTitle='Email Sent'
  successBody='You will recieve an introductory email shortly with additional information regarding this vendor'
  failureTitle='We are sorry!'
  failureBody="We couldn't send your your email, please try again later" />

<CopyToClipboard bind:text={textToCopy} />
