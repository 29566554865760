<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';

  import {createEventDispatcher} from 'svelte';

  export let selectedApps;
  export let onPageChange;

  let searchCriteria;

  const MINIMUM_AMOUNT_OF_COMPARABLE_APPS = 2;
  const dispatch = createEventDispatcher();

  const handleSearch = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    dispatch('searchApplication', {
      searchCriteria
    });
  };

  $: if(searchCriteria === ''){
    onSearch() ;
  }
</script>

<style>
  .adjust-top {
    position: relative;
    top: -14.5px;
  }
  .btn-demo {
    background-color: var(--cox2m-clr-brand-400);
    font-size: var(--cox2m-fs-1000);
  }
  .btn-secondary {
    cursor: default;
  }
</style>

<div
  class="row d-flex align-items-center justify-content-between border-bottom
  py-2 bg-white adjust-top">
  <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
    <TextInput
      id='appstore-search-input'
      icon="search"
      customContainerClass="shadow-sm"
      placeholder="Search apps names, categories, vendor or description"
      bind:value={searchCriteria}
      onKeyPress={e => handleSearch(e)} />
  </div>
  <div class="col-md-auto col-xs-12 col-sm-12">
    <button
      id={'compare-selections-button'}
      type="button"
      class="btn text-white shadow-sm px-5 py-2 {selectedApps.length < MINIMUM_AMOUNT_OF_COMPARABLE_APPS ? 'btn-secondary' : 'btn-demo'}"
      disabled={selectedApps.length < MINIMUM_AMOUNT_OF_COMPARABLE_APPS}
      on:click={() => {
        onPageChange('comparisonScreen', selectedApps);
      }}>
      Compare Selections
    </button>
  </div>
</div>
