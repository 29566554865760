export const staticMenuOptions = () => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'app-store',
    path: '/app-store',
    name: 'App Store',
    icon: 'copy',
    shouldShow: true,
    alternateSelectionCondition: () => true //should be selected always
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];
