<script>
	import { afterUpdate } from 'svelte';

	export let text;

	let textarea;

	afterUpdate(() => {
    if(text !== ''){
      document.getElementById('text-copy-element').select();
      document.getElementById('text-copy-element').focus();
      document.execCommand('copy');
    }
	});
</script>
<style>
  .hidden-element{
    width: 0px;
    height: 0px;
    font-size: 0px;
  }
</style>
<textarea class="hidden-element b-0" bind:value={text} bind:this={textarea} id="text-copy-element"></textarea>

