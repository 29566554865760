<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import ConfirmationModal from '../shared/ConfirmationModal.svelte';
  import CopyToClipboard from '../shared/CopyToClipboard.svelte';

  import {MOBILE_REGEX_PART_1, MOBILE_REGEX_PART_2} from '../../constants';

  export let vendorPhone;
  export let vendorEmail;

  let textToCopy = '';

  window.mobileCheck = () => {
    let check = false;
    (function(a) {
      if (
        MOBILE_REGEX_PART_1.test(a) ||
        MOBILE_REGEX_PART_2.test(a.substr(0, 4))
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const copyToClipboard = () => {
    textToCopy = '.';
    textToCopy = vendorPhone;
  };

  const callVendorConfirmationFunction = () => {
    if (window.mobileCheck()) {
      window.open('tel:' + vendorPhone);
    } else {
      copyToClipboard();
    }
  };

  const emailVendorConfirmationFunction = () => {
    window.open(`mailto:${vendorEmail}`);
  };
</script>

<style>
  .back-button {
    min-width: 100px;
  }
  .btn-outline-primary {
    color: var(--cox2m-clr-brand-400);
    border-color: var(--cox2m-clr-brand-400);
  }
  .btn-outline-primary:hover {
    color: var(--cox2m-clr-neutral-white);
    background-color: var(--cox2m-clr-brand-400);
  }
  .mw-50 {
    min-width: 50%;
  }
  .mw-50 button {
    max-width: 250px;
    min-height: var(--cox2m-spacing-10-units);
  }
</style>

<div class="w-100 row justify-content-between align-items-center">
  <div class="col mr-5 mb-2">
    <button
      type="button"
      class="btn btn-light bg-white border shadow-sm"
      id="back-button"
      on:click={() => {
        window.history.back();
      }}>
      <div class="col-sm-auto align-items-center back-button">

        <Icon
          icon="chevron-left"
          size="var(--cox2m-spacing-3-units)"
          color="var(--cox2m-clr-neutral-black)"
          className="mb-1 btn p-1 active-app-check-icon rounded-circle" />
        Back
      </div>
    </button>
  </div>
  <div class="ml-2 row mw-50">
    {#if vendorPhone}
      <button
        class="col mx-1 btn btn-outline-primary"
        data-toggle="modal"
        data-target="#call-vendor-modal"
        id="call-vendor-modal-button"
        type="button">
        Call Vendor
      </button>
    {/if}
    {#if vendorEmail}
      <button
        class="col mx-1 btn btn-outline-primary"
        data-toggle="modal"
        id="email-vendor-modal-button"
        data-target="#email-vendor-modal"
        type="button">
        Email Vendor
      </button>
    {/if}
    <div />
  </div>
</div>

<ConfirmationModal
  id="call-vendor-modal"
  defaultTitle={'Contact Vendor?'}
  defaultBody={'Click confirm to start a call with you and the vendor'}
  confirmationButtonText={'Yes, Call Vendor'}
  confirmationFunction={callVendorConfirmationFunction}
  successTitle={'Hang on there!'}
  successBody={'we are about to make your call'}
  failureTitle={'We are sorry!'}
  failureBody={"We couldn't place your call, please try again later"} />

<ConfirmationModal
  id="email-vendor-modal"
  defaultTitle='Contact Vendor?'
  defaultBody='Click confirm to start an email with you and the vendor'
  confirmationButtonText='Yes, Email Vendor'
  confirmationFunction={emailVendorConfirmationFunction}
  successTitle='Email Sent'
  successBody='You will recieve an introductory email shortly with additional information regarding this vendor'
  failureTitle='We are sorry!'
  failureBody="We couldn't send your your email, please try again later" />

<CopyToClipboard text={textToCopy} />
