import {get, writable} from 'svelte/store';

const persist = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Writable store with local storage support
 * @param key
 * @param initialValue
 * @returns {{set(*=): void, subscribe: <T>(run: Subscriber<any>, invalidate?: Invalidator<any>) => Unsubscriber, update(*=): void}}
 */
export const writableLocal = (key, initialValue) => {
    const sessionValue = JSON.parse(localStorage.getItem(key));
    if (!sessionValue) persist(key, initialValue);
    const store = writable(sessionValue || initialValue);
    const {set: realSet, subscribe, update: realUpdate} = store;

    return {
        set(value) {
            realSet(value);
            persist(key, value);
        },
        subscribe,
        update(fn) {
            realUpdate(fn);
            persist(key, get(store));
        }
    };
};
