import VendorDetails from './forms/app-store/VendorDetails.svelte';
import AppDetails from './forms/app-store/AppDetails.svelte';
import AppStore from './forms/app-store/AppStore.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/app-store': AppStore,
  '/app-store/app/:id': AppDetails,
  '/app-store/vendor/:category/:id': VendorDetails,

  '*': NotFoundPage
};

export const openRoutes = []

export default routes
