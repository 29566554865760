<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  export let onPageChange;
</script>

<style>
  .back-button {
    min-width: var(--cox2m-spacing-25-units);
  }
</style>

<div class="w-100 row justify-content-between align-items-center">
  <div class="col mr-5">
    <button
      type="button"
      class="btn btn-light bg-white border shadow-sm"
      on:click={() => {
        onPageChange('appStore');
      }}>
      <div class="col-sm-auto align-items-center back-button">

        <Icon
          icon="chevron-left"
          size="var(--cox2m-spacing-3-units)"
          color="var(--cox2m-clr-neutral-black)"
          className="mb-1 btn p-1 active-app-check-icon rounded-circle" />
        Back
      </div>
    </button>
  </div>
</div>
