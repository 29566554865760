<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import AppStoreErrorMessage from '../../components/app-store/AppStoreErrorMessage.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import AppStoreSubHeader from '../../components/app-store/AppStoreSubHeader.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import ComparisonScreen from '../../forms/app-store/ComparisonScreen.svelte';
  import AppGallery from '../../components/app-store/AppGallery.svelte';
  import Unauthorized from '../../modals/Unauthorized.svelte';

  import {shouldDisplayUnauthorizedModal, getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {getAppStoreListing} from '../../actions';
  import {menuOpts, user} from '../../stores';
  import {onMount} from 'svelte';

  const token = getCookieByName(`${"ENV"}_token`);

  let modalContext;

  let appFetchingError = false;
  let appStoreLoader = false;
  let selectedApps = [];
  let searchCriteria;
  let apps = [];

  let currentPageDisplay = {
    comparisonScreen: false,
    appStore: true
  };

  const pageChanger = (pagePath = {}) => {
    Object.keys(currentPageDisplay).map(page => {
      page === pagePath
        ? (currentPageDisplay[page] = true)
        : (currentPageDisplay[page] = false);
    });
    if (pagePath === 'appStore') {
      selectedApps = [];
    }
  };

  onMount(async () => {
    appStoreLoader = true;
    let response = await getAppStoreListing(token);
    if (response.fulfilled) {
      apps = response.collection;
    } else {
      if(response.status === 401){
        shouldDisplayUnauthorizedModal(modalContext, Unauthorized);
      }else{
        appFetchingError = true;
      }
    }
    appStoreLoader = false;
  });
</script>

<Modal bind:this={modalContext}>
  <MainScreen title="App Store" menuOpt={$menuOpts} user={$user} appSlug="extra-smart" showAlertsBanner={false}>
    <div slot="main-dashboard">
      {#if appStoreLoader}
        <div class="row col w-100 h-100 justify-content-center align-content-center">
          <LoadingSpinner />
        </div>
      {:else if !appFetchingError}
        {#if currentPageDisplay.appStore}
          <AppStoreSubHeader
            {selectedApps}
            onPageChange={pageChanger}
            on:searchApplication={e => {
              searchCriteria = e.detail.searchCriteria;
            }} />
          <div class="container-fluid ">
            <div class="row py-2">
              <AppGallery
                {searchCriteria}
                categories={apps}
                categoriesBackup={apps}
                on:selection={e => (selectedApps = e.detail.selectedApps)} />
            </div>
          </div>
        {/if}
        {#if currentPageDisplay.comparisonScreen}
          <ComparisonScreen
            {token}
            appsToCompare={selectedApps}
            onPageChange={pageChanger} />
        {/if}
      {:else}
        <AppStoreErrorMessage />
      {/if}
    </div>
  </MainScreen>
</Modal>
