<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import {push} from 'svelte-spa-router';
</script>

<style>
  .btn-primary {
    background-color: var(--cox2m-clr-brand-700);
    border-color: var(--cox2m-clr-brand-700);
  }
</style>

<div class="row col w-100 h-100 justify-content-center align-content-center">
  <div class="row w-75 justify-content-center mb-2">
    <Icon
      icon='cross-circle'
      color='var(--cox2m-clr-critical-500)'
      size='var(--cox2m-spacing-10-units)'
      className="rounded-circle" />
  </div>
  <div class="row w-75 justify-content-center mt-3">
    <h3 class="text-center" id="app-detail-fetching-error">
      We are sorry! we had some troubles getting this app information, please try again later
    </h3>
    <button
      class="btn btn-primary refresh-button shadow-sm px-5 py-2 mt-3"
      on:click={() => push('/app-store')}>
      Go back
    </button>
  </div>
</div>
