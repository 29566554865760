<script>
  export let screenshots = [];
</script>

<style>
  .carousel-image {
    max-height: 400px;
    width: auto;
    margin: 0 auto;
  }
  .carousel {
    background: var(--cox2m-clr-neutral-black);
    max-width: 60%;
    margin: 0 auto;
  }
</style>

<div
  id="app-screenshots"
  class="carousel slide carousel-fade mt-5"
  data-ride="carousel">
  <div class="carousel-inner" role="listbox">
    <div class="row">
      <div class="col">
        {#each screenshots as screenshot, i}
          <div
            class="w-100 d-flex {i == 0 ? 'active carousel-item' : 'carousel-item'}">
            <img
              class="d-block carousel-image"
              src={'https://source.unsplash.com/random/400x600' }
              alt={screenshot} />
          </div>
        {/each}
      </div>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#app-screenshots"
    role="button"
    id="slider-previous-button"
    data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true" />
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#app-screenshots"
    role="button"
    id="slider-next-button"
    data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true" />
    <span class="sr-only">Next</span>
  </a>
</div>

