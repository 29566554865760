<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {push} from 'svelte-spa-router';

  export let onAppSelection = () => {};
  export let showCheckBoxes = false;
  export let checked = false;
  export let appData = {};
  export let appId;

  const BASE_VENDOR_ROUTE = '/app-store/vendor/';
  const BASE_APPLICATION_DETAILS_ROUTE = '/app-store/app/';

  const goToAppDetailPage = () => {
    push(
      `${BASE_APPLICATION_DETAILS_ROUTE}${appId}`
    );
  };

  const goToVendorPage = () => {
    push(
      `${BASE_VENDOR_ROUTE}${appData.categoryName}/${appData.vendor.vendorId}`
    );
  };
</script>

<style>
  .active-card-yellow-border {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 5px;
    width: calc(100% + var(--cox2m-visual-corrector));
    position: absolute;
    right: -1px;
    top: calc(100% - var(--cox2m-spacing-1-units));
  }
  .app-title:hover,
  .vendor-title:hover {
    text-decoration: underline;
  }
</style>

<div class="col-md-4 my-2">
  <div
    id={appId}
    class="col-xs-12 bg-white p-3 card border-light card shadow h-100 m-auto
    mw-25">
    <h5 class="app-title">
      <a
        class="text-dark"
        on:click={goToAppDetailPage}
        href="javascript:void(0);"
        id={appId + '-title'}>
        {appData.name}
      </a>
    </h5>
    <p class="ml-0 my-1 text-truncate">{appData.description}</p>
    <p class="ml-0 mb-3 vendor-title">
      <a
        class="text-dark"
        id={appId + '-vendor-name'}
        on:click={goToVendorPage}
        href="javascript:void(0);">
        {appData.vendor.name}
      </a>
    </p>
    <div class="row justify-content-space-between">
      {#if showCheckBoxes}
        <div class="col">
          <input
            id={appId + '-checkbox'}
            class="input-checkbox"
            type="checkbox"
            bind:checked
            on:change={e => onAppSelection(appData, e)} />
          <span class="ml-1">Compare</span>
        </div>
      {/if}
      <div class="px-3">
        {#if appData.status == 'Active'}
          <Icon
            icon="check"
            size="8px"
            color="var(--cox2m-clr-neutral-white)"
            className="mb-1 btn p-1 btn-warning active-app-check-icon
            rounded-circle" />
        {/if}
        <em>{appData.status}</em>
      </div>
    </div>
    {#if appData.status == 'Active'}
      <div class="active-card-yellow-border bg-warning" />
    {/if}
  </div>
</div>
