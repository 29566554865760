<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
</script>

<style>
  .card-summary {
    font-weight: var(--cox2m-fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cox2m-clr-neutral-black);
  }

  .icon-container {
    width: var(--cox2m-spacing-16-units);
    height: var(--cox2m-spacing-16-units);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cox2m-semantic-critial-color);
    border-radius: var(--cox2m-spacing-1-units);
    align-self: center;
  }
</style>

<div id="unauthorized-container" class="container p-3" style="height: 400px;">
  <div
    class="card mx-3 mb-3 border-0"
    style="display: grid; grid-template-columns: var(--cox2m-spacing-16-units) auto;">
    <div class="icon-container">
      <Icon icon="alert-triangle" size="var(--cox2m-spacing-7-units)" color="var(--cox2m-clr-neutral-white)" />
    </div>
    <div class="card-body py-3">
      <h2 class="card-summary m-0">Unauthorized</h2>
    </div>
  </div>
  <p class="mx-3">
    You are unauthorized to see these Dashboards. Please ask your administrator
    to add the needed permissions to see this app.
  </p>
</div>
