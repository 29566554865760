<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import FeatureColumn from '../FeatureColumn.svelte';


  import {getAppsVendorsById} from '../../actions.js';
  import {CITY_STATE_REGEX} from '../../constants.js';
  import {onMount} from 'svelte';
  import {css} from 'emotion';

  export let appsToCompare;
  export let token;

  const columns = [
    {label: 'Feature', key: 'feature', Component: FeatureColumn}
  ];
  const rowsObj = {};
  const rows = [];

  appsToCompare.forEach(app => {
    columns.push({label: app.vendor.name, key: app.vendor.vendorId});
  });

  let intermediateVendorsInformation = [];
  let vendorsWereFetchedCorrectly;
  let pageLoading = true;

  const getCityAndStateFromText = text => {
    if (text && text.length > 0 && CITY_STATE_REGEX.exec(text)) {
      return CITY_STATE_REGEX.exec(text)[0];
    }
    return text;
  };

  const fetchVendorInformation = () => {
    let fulfilledVendorsCall = 0;
    return new Promise(resolve => {
      appsToCompare.forEach(async app => {
        const response = await getAppsVendorsById(token, app.vendor.vendorId);
        if (response.fulfilled) {
          fulfilledVendorsCall += 1;
          intermediateVendorsInformation.push(response.vendor);
        } else {
          pageLoading = false;
          resolve(false);
        }
        if (fulfilledVendorsCall === appsToCompare.length) {
          resolve(intermediateVendorsInformation);
        }
      });
    });
  };

  const getVendorsInformation = () => {
    fetchVendorInformation().then(data => {
      if (data) {
        vendorsWereFetchedCorrectly = true;
        buildVendorComparisonTableRows(data);
        pageLoading = false;
      } else {
        vendorsWereFetchedCorrectly = false;
      }
    });
  };

  onMount(() => {
    getVendorsInformation();
  });

  const buildVendorComparisonTableRows = vendors => {
    vendors.forEach(vendor => {
      if (vendor.address) {
        if (rowsObj['address']) {
          rowsObj['address'][vendor.vendorId] = getCityAndStateFromText(
            vendor.address
          );
        } else {
          rowsObj['address'] = {
            [vendor.vendorId]: getCityAndStateFromText(vendor.address)
          };
        }
      }
      if (vendor.founded) {
        if (rowsObj['founded']) {
          rowsObj['founded'][vendor.vendorId] = new Date(
            vendor.founded
          ).getFullYear();
        } else {
          rowsObj['founded'] = {
            [vendor.vendorId]: new Date(vendor.founded).getFullYear()
          };
        }
      }
    });

    for (const key in rowsObj) {
      if (Object.hasOwnProperty.call(rowsObj, key)) {
        rows.push({feature: key, ...rowsObj[key]});
      }
    }
  };
</script>

{#if pageLoading}
  <div
    class="row col w-100 mt-5 pt-5 justify-content-center align-content-center">
    <LoadingSpinner />
  </div>
{:else}
  {#if vendorsWereFetchedCorrectly}
    <Table
      id='vendor-comparison-table'
      columnRowClassName="text-white {css`background-color: var(--cox2m-clr-brand-700) !important;`}"
      containerClassName="table-container border rounded bg-white text-dark
      overflow-auto"
      {columns}
      {rows} />
  {:else}
    <div class="text-center mt-5 px-5">
      <h3 id="vendor-fetching-error-message">
        We are sorry!, we cannot display this comparison right now, please try
        again later or contact the support team
      </h3>
    </div>
  {/if}
{/if}
