<script>
    export let features = [];

    let chunckedCategories;
    const COLUMN_NUMBERS = 2;

    const chunkify = (arr, size = COLUMN_NUMBERS) => {
      return Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
    };

    chunckedCategories = chunkify(features);
</script>

<style>
  .list-item {
    height: unset;
  }
  .link {
    color: var(--cox2m-clr-brand-400);
    cursor: pointer;
    text-decoration: underline;
  }
  .d-inline-grid{
   display: inline-grid;
  }
  .mw-450{
   max-width: 450px;
  }
</style>

<div class="row ml-2">
  <ul class="list-unstyled row p-0 w-100" id="feature-list">
    {#each chunckedCategories as chunk}
      {#each chunk as feature}
        {#if feature.value != null}
          <li class="col-5 list-item px-2 my-2 ">
            <h6 class="d-inline">
              <b>{feature.name}:</b>
            </h6>
            {#if feature.link}
            <span class="d-inline-grid mw-450">
              <p class="text-truncate">
                <a class="link text-truncate" href={feature.value}>
                  {feature.value}
                  {#if feature.additionalText !== null && feature.additionalText }
                    {feature.additionalText}
                  {/if}
                </a>
              </p>
            </span>
            {:else}
            <span >
              {feature.value}
              {#if feature.additionalText !== null && feature.additionalText }
                {feature.additionalText}
              {/if}
            </span>
            {/if}
          </li>
        {/if}
      {/each}
    {/each}
  </ul>
</div>
