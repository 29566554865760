<script>
  import AppCard from './AppCard.svelte';

  import {createEventDispatcher} from 'svelte';

  export let applicationContainerClass = 'container-fluid';
  export let showCategoryTitles = true;
  export let showCheckBoxes = true;
  export let categoriesBackup;
  export let categories = [];
  export let searchCriteria;

  let selectedApps = [];
  let applicationsFound;
  let cardsContainer;

  const MAXIMUM_COMPARABLE_ITEMS = 3;
  const dispatch = createEventDispatcher();

  const appSelectionHandler = (app, e) => {
    if (
      selectedApps.findIndex(it => it.id === app.id) === -1 &&
      selectedApps.length < MAXIMUM_COMPARABLE_ITEMS
    ) {
      selectedApps = [...selectedApps, app];
      //Do not remove line 27 since is useful for unit testing consistency. line 27 => e.target.checked = true;
      e.target.checked = true;
    } else {
      selectedApps = selectedApps.filter(it => it.id !== app.id);
      e.target.checked = false;
    }
    selectionEvent();
  };

  const appSelectionEvent = (app, e) => {
    appSelectionHandler(app, e);
  };

  const selectionEvent = () => {
    dispatch('selection', {
      selectedApps: selectedApps
    });
  };

  const searchApplications = async searchCriteria => {
    categories = categoriesBackup;
    const searchRegex = new RegExp(searchCriteria, 'i');
    let search = {category: 'Search Results', apps: []};
    return new Promise(resolve => {
      if (searchCriteria != '' && searchCriteria) {
        categories.map(category => {
          category.apps.map(app => {
            if (
              searchRegex.test(app.name) ||
              searchRegex.test(app.vendor.name) ||
              searchRegex.test(app.categoryName) ||
              searchRegex.test(app.description)
            ) {
              applicationsFound = true;
              search.apps.push(app);
            }
          });
        });
        if (search.apps.length === 0) {
          applicationsFound = false;
        }
        resolve([search]);
      } else {
        resolve(false);
      }
    });
  };

  const isAppIdSelected = (id) => {
    return selectedApps.findIndex(it => id === it.id) !== -1;
  }

  $: {
    searchApplications(searchCriteria).then(data => {
      if (data) {
        categories = [];
        setTimeout(() => {categories = data}, 50);
      } else {
        categories = categoriesBackup;
      }
    });
  }
</script>

{#if searchCriteria && !applicationsFound || !categories.length}
  <div class="container-fluid py-4" id="not-applications-found">
    <h3>No Applications were found with this search criteria</h3>
  </div>
{:else}
  {#each categories as category}
    {#if showCategoryTitles}
      <div class="container-fluid py-4">
        <h3>{category.category}</h3>
      </div>
    {/if}
    <div class={applicationContainerClass} id="gallery-apps-container" bind:this={cardsContainer}>
      <div class="row ">
        {#each category.apps as app}
          <AppCard
            appId={app.id}
            onAppSelection={appSelectionEvent}
            appData={app}
            checked={isAppIdSelected(app.id)}
            {showCheckBoxes} />
        {/each}
      </div>
    </div>
  {/each}
{/if}
