<script>
  import VendorFetchingErrorMessage from '../../components/app-vendor/VendorFetchingErrorMessage.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import AppVendorHeader from '../../components/app-vendor/AppVendorHeader.svelte';
  import FeatureList from '../../components/app-details/FeatureList.svelte';
  import AppGallery from '../../components/app-store/AppGallery.svelte';
  import Unauthorized from '../../modals/Unauthorized.svelte';

  import {getAppsVendorsById} from '../../actions.js';
  import {CITY_STATE_REGEX} from '../../constants.js';
  import {menuOpts, user} from '../../stores';
  import {onMount} from 'svelte';
  import {
    shouldDisplayUnauthorizedModal,
    getCookieByName
  } from '@cox2m/city-services-ui-components/src/funcs';

  export let params;

  const token = getCookieByName(`${"ENV"}_token`);

  let modalContext;
  let vendorSpecialization = unescape(params.category);
  let vendorFetchingError = false;
  let pageLoading = false;
  let vendorDetails = [];
  let vendorFeatures = [];

  const getCityAndStateFromText = text => {
    if (text && text.length > 0 && CITY_STATE_REGEX.exec(text)) {
      return CITY_STATE_REGEX.exec(text)[0];
    }
    return text;
  };

  const createVendorFeatures = vendorDetails => {
    vendorFeatures.push(
      {name: 'Website', value: vendorDetails.website, link: true},
      {
        name: 'Headquearters',
        value: getCityAndStateFromText(vendorDetails.headquarters)
      },
      {
        name: 'Organization Status',
        value: vendorDetails.organizationStatus
      },
      {
        name: 'Active Cities',
        value: vendorDetails.activeCities,
        additionalText: ' locations'
      }, //this might be activeCities.length
      {
        name: 'Active Users',
        value: vendorDetails.activeUsers,
        additionalText: ' installs'
      },
      {name: 'Founded', value: vendorDetails.founded === null? null: new Date(vendorDetails.founded).getFullYear()}
    );
  };

  onMount(async () => {
    pageLoading = true;
    const response = await getAppsVendorsById(token, params.id);
    if (response.fulfilled) {
      vendorDetails = response.vendor;
      createVendorFeatures(vendorDetails);
    } else {
      if (response.status === 401) {
        shouldDisplayUnauthorizedModal(modalContext, Unauthorized);
      } else {
        vendorFetchingError = true;
      }
    }
    pageLoading = false;
  });
</script>

<style>
  #vendor-info img {
    min-width: var(--cox2m-spacing-25-units);
    max-width: 200px;
    max-height: 150px;
  }
</style>

<Modal bind:this={modalContext}>
  <MainScreen title="Vendor Details" menuOpt={$menuOpts} user={$user} appSlug="extra-smart" showAlertsBanner={false}>
    <div slot="main-dashboard">
      {#if pageLoading}
        <div class="row col w-100 h-100 justify-content-center align-content-center">
          <LoadingSpinner />
        </div>
      {:else}
        {#if !vendorFetchingError}
          <div
            class="row d-flex align-items-center justify-content-between px-5 py-3
            adjust-top">
            <AppVendorHeader
              vendorPhone={vendorDetails.phoneNumber}
              vendorEmail={vendorDetails.emailAddress} />
            <div class="mt-5 ">
              <div id="vendor-info" class="row align-content-center">
                <div class="mr-3 d-flex align-items-center">
                  <img
                    id="vendor-logo"
                    class="image-fluid ml-3 mb-2"
                    src={vendorDetails.logoUrl}
                    alt="{vendorDetails.name} logo" />
                </div>
                <div class="col">
                  <h2 class="mb-3">
                    <b id="vendor-name">{vendorDetails.name || ''}</b>
                  </h2>
                  <h4 class="mb-3 text-truncate">
                    <b id="vendor-specialization">{vendorSpecialization}</b>
                  </h4>
                </div>
              </div>
              <div id="general-info">
                <p class="col-lg-8 px-0 my-5" id="vendor-description">{vendorDetails.companyDescription}</p>
                {#if vendorFeatures.length > 0}
                <h5>
                  <b>Company Information</b>
                </h5>
                  <FeatureList features={vendorFeatures} />
                {/if}
              </div>
              {#if vendorDetails.apps && vendorDetails.apps.length > 0}
                <div id="published-apps" class="p-0 mt-5">
                  <h5>
                    <b>Published Applications</b>
                  </h5>
                  <AppGallery
                    categories={vendorDetails.apps}
                    showCheckBoxes={false}
                    showCategoryTitles={false}
                    applicationContainerClass={''} />
                </div>
              {/if}
            </div>
          </div>
        {:else}
          <VendorFetchingErrorMessage />
        {/if}
      {/if}
    </div>
  </MainScreen>
</Modal>
